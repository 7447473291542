<template>
    <f7-page name="manual">
        <!-- Navbar -->
        <f7-navbar title="Lieferadresse" back-link>
            <f7-subnavbar :inner="false">
                <f7-searchbar
                    placeholder="suchen"
                    search-container=".virtual-list"
                    search-item="li"
                    search-in=".item-title"
                    :disable-button="false"
                    @searchbar:search="setSearchString"
                ></f7-searchbar>
            </f7-subnavbar>
        </f7-navbar>

        <!-- Customer List (favorites) -->
        <f7-list v-if="searchString === ''" media-list style="margin-bottom: 0px">
            <ul>
                <f7-list-item
                    v-if="$root.$data.is_reseller == false || typeof $root.$data.is_reseller == 'undefined'"
                    title="Favoriten"
                    group-title
                ></f7-list-item>
                <f7-list-item v-else title="Meine Adresse(n)" group-title></f7-list-item>
                <f7-list-item
                    v-for="(item, index) in favorites"
                    :key="index"
                    :title="item.name1"
                    :subtitle="item.address1 + ', ' + item.postalcode + ' ' + item.place"
                    :style="`top: ${vlData.topPosition}px`"
                    link=""
                    @click="addCustomer(item.id)"
                >
                </f7-list-item>
            </ul>
        </f7-list>

        <!-- Customer list (all) -->
        <f7-list
            v-if="$root.$data.is_reseller == false || typeof $root.$data.is_reseller == 'undefined'"
            media-list
            virtual-list
            :virtual-list-params="{
                items,
                searchAll,
                renderExternal,
                height: $theme.ios ? 69 : $theme.md ? 73 : 73,
            }"
        >
            <ul>
                <f7-list-item title="Alle Shops" group-title></f7-list-item>
                <f7-list-item
                    v-for="(item, index) in vlData.items"
                    :key="index"
                    :title="item.name1"
                    :subtitle="item.address1 + ', ' + item.postalcode + ' ' + item.place"
                    :style="`top: ${vlData.topPosition}px`"
                    @click="addCustomer(item.id)"
                >
                </f7-list-item>
            </ul>
        </f7-list>
        <!-- <f7-button fill large @click="$f7router.navigate('/selectDeliveryDate/')">Weiter</f7-button> -->
    </f7-page>
</template>
<script>
const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();

export default {
    data() {
        return {
            items: [],
            favorites: [],
            vlData: {
                items: [],
            },
            searchString: "",
        };
    },

    props: [""],

    methods: {
        searchAll(query, items) {
            const found = [];
            for (let i = 0; i < items.length; i += 1) {
                if (
                    items[i].name1.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
                    items[i].place.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
                    query.trim() === ""
                )
                    found.push(i);
            }
            return found;
        },
        renderExternal(vl, vlData) {
            this.vlData = vlData;
        },
        setSearchString(searchbar, query) {
            this.searchString = query;
        },
        addCustomer(customerId) {
            this.$root.$data.order.customerId = customerId;
            this.$f7router.navigate("/orderOptionalFields/", {
                reloadAll: false,
            });
        },
    },

    beforeMount() {
        // Load all customers
        const _this = this;
        this.items = this.$root.$data.customers;

        // Load & sort favorites
        let responsible = this.$root.$data.responsible;
        responsible = responsible.split(";");

        this.favorites = this.items.filter((customer) => {
            return responsible.includes(customer.responsible);
        });
        this.favorites = this.favorites.sort((a, b) => {
            if (a.name1 < b.name1) {
                return -1;
            } else {
                return 1;
            }
        });
    },

    computed: {},
};
</script>

<style>
.list {
    margin-top: 0px;
}
.button {
    margin: 80px 10px 0px 10px;
}
/* .badge {
    background: #2196f3 !important;
} */
</style>
