<template>
    <f7-page name="new">
        <!-- Navbar -->
        <f7-navbar title="Neuheiten" back-link> </f7-navbar>
        <!-- Product list / search results -->
        <f7-list media-list>
            <f7-list-item
                v-for="(product, index) in filteredNewProducts"
                :key="index"
                :title="decode(decodeURIComponent(product.name))"
                :subtitle="product.id + ' / ' + product.price.toFixed(2)"
                :link="`/addCart/${product.id}/`"
            >
                <div v-if="!$f7.online" class="skeleton-block" style="width: 44px; height: 44px;" slot="media"></div>
                <img v-if="$f7.online" slot="media" :src="product.img" width="44" />
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>
<script>
const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();

export default {
    data() {
        return {
            productsFiltered: [],
            searchString: "",
        };
    },
    props: ["products"],

    methods: {
        setSearchString(searchbar, query) {
            this.searchString = query;
        },
        decode(string) {
            return entities.decode(string);
        },
    },
    computed: {
        filteredNewProducts: function() {
            console.log("filtering...");
            return this.$root.$data.products
                .filter(function(product) {
                    return product.newproduct === true;
                })
                .sort((a, b) => b.added - a.added);
        },
    },
};
</script>

<style scoped>
.list {
    margin-top: 0px;
}
</style>
